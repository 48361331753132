<mat-card class="mat-elevation-z0">

  <mat-card-header>Search Download Outputs</mat-card-header>
  <br>
  <form novalidate [formGroup]="Search" autocomplete="off" (ngSubmit)="run()">
    <mat-card-content>
      <mat-form-field>
        <mat-label>Batch ID</mat-label>        
        <mat-select id="batch_id" formControlName="batch_id" >          
          @for (batch of batchList; track batch) {
            <mat-option [value]="batch">{{batch}}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Process ID</mat-label>
        <mat-select id="process_id" formControlName="process_id">
          <mat-option value="VIRREPORT-GA">Galaxy Australia</mat-option>
          <mat-option value="VIRREPORT-NCBI">Nextflow NCBI</mat-option>
          <mat-option value="VIRREPORT-PVIRDB">Nextflow PVirDB</mat-option>
          <mat-option value="VIRREPORT-PVIRDB-NCBI">Nextflow PVirDB and NCBI</mat-option>
        </mat-select>
      </mat-form-field>


    </mat-card-content>
    <mat-card-actions>
      <button style="width:200px;color:white" mat-raised-button [style.background]="'#748b31'" type="submit" [disabled]="!Search.valid ">
        Search
      </button>
      <span></span>
      <button style="width:200px" type="button" mat-raised-button color="warn" (click)="reset()" matTooltip="Click here to Cancel">
        Clear
      </button>
    </mat-card-actions>
  </form>
  <br>
  <br>  
  <div class="mat-elevation-z8" [hidden]="!recordFound">
  <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
 
  <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{ column.field }}">
    <mat-header-cell *matHeaderCellDef>{{ column.field }}
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <ng-container  *ngIf ="i== 0"> {{ row.sample }}</ng-container>
      <ng-container  *ngIf ="i== 1">
        <button type="button" mat-button color="primary" (click)="download(row.filePath,row.name)" matTooltip="{{ row.name }}">{{ row.name }}</button>
      </ng-container>
    </mat-cell>       
  </ng-container>
  <mat-header-row mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <!-- Group header -->
   <ng-container matColumnDef="groupHeader">
        <mat-cell colspan="999" *matCellDef="let group">
          <mat-icon *ngIf="group.expanded">expand_less</mat-icon>
          <mat-icon *ngIf="!group.expanded">expand_more</mat-icon>
        <strong>{{groupByColumns[group.level-1]}} - {{group[groupByColumns[group.level-1]]}} ({{group.totalCounts}})</strong>
        </mat-cell>
    </ng-container>
    
    <mat-row *matRowDef="let row; columns: ['groupHeader']; when: isGroup" (click)="groupHeaderClick(row)"> </mat-row>
  
</mat-table>
  <mat-paginator [pageSizeOptions]="[10, 50, 100]"></mat-paginator>
</div>


  <br>
</mat-card>