import { Component, OnInit, ChangeDetectorRef, ViewChild, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Constants } from '../shared/constants.all';
import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { LoaderService } from "../loader/loader.service";
import { Services } from "../shared/services";
import { ConfigService } from "../shared/configService";
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';


export interface Urls {
  process: string;
  sample: string;
  name: string;
  filePath: string;
}

export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}
@Component({
  selector: "App-AzureBlobDownload",
  templateUrl: "AzureBlobDownload.Component.html",
  styleUrls: ["AzureBlobDownload.Component.css"]
})
export class AzureBlobDownload implements OnInit, OnDestroy {
  apiURL: string;
  Search: FormGroup;

  public dataSource = new MatTableDataSource<any | Group>([]);
  recordFound: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private subs = new Subscription();
  private dataArray: any;
  batchList: any;
  columns: any[];
  displayedColumns: string[];
  groupByColumns: string[] = [];

  constructor(private _router: Router, fb: FormBuilder, private _Service: Services, private _loader: LoaderService, private _appConfigService: ConfigService,
    private _changeDetector: ChangeDetectorRef) {
    this.Search = new FormGroup(
      {
        batch_id: new FormControl({ value: '', disabled: Constants.FALSE },
          [Validators.required]),
        process_id: new FormControl({ value: '', disabled: Constants.FALSE },
          [Validators.required]),
      });
      this.columns = [{
        field: 'Sample'
      }, 
      {
        field: 'Name'
      }];
      
    this.displayedColumns = this.columns.map(column => column.field);
    this.groupByColumns = ['sample'];
  }


  async ngOnInit() {
    var batchUrl = this._appConfigService.getConfig().BaseURL + this._appConfigService.getConfig().APIGetBatches;
   this._Service.getBatches(batchUrl)
   .subscribe((res) => {
     this.batchList = res;
   });
  }

  public download(path: string, name: string) {   
    var downloadUrl = this._appConfigService.getConfig().BaseURL + this._appConfigService.getConfig().APIDownloadBatchDataFile + "/" + path;
    this._Service.getBlobFromAPI(downloadUrl).subscribe((data) => {
      var newBlob = new Blob([data], { type: "application/octet-stream" });
      var url = window.URL.createObjectURL(newBlob);
      
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute('target', '_blank');
      //a.style = "display: none";
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  public run() {     
    this._loader.show();
    this.apiURL = this._appConfigService.getConfig().BaseURL + this._appConfigService.getConfig().APIDownloadBatchData + "/" + this.Search.controls["batch_id"].value +"/" + this.Search.controls["process_id"].value;
    console.log(this.apiURL);
    this.subs.add(this._Service.getBatchSearch(this.apiURL)
      .subscribe((res) => {
        
        this.dataArray = res;
        this.dataArray.forEach((item, index) => {
          item.id = index + 1;
        });
        this.dataSource.data = this.addGroups(this.dataArray, this.groupByColumns);
        this.dataSource.filterPredicate = this.customFilterPredicate.bind(this);
        this.checkGroupByColumn('sample', true);
        this.dataSource.data = this.addGroups(this.dataArray, this.groupByColumns);
        this.dataSource.filter = performance.now().toString();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.recordFound = true;                        
        this._loader.hide();
      },
        (err: HttpErrorResponse) => {
          console.log(err);
        }));
  }

  checkGroupByColumn(field, add ) {
    let found = null;
    for (const column of this.groupByColumns) {
      if (column === field) {
        found = this.groupByColumns.indexOf(column, 0);
      }
    }
    if (found != null && found >= 0) {
      if (!add) {
        this.groupByColumns.splice(found, 1);
      }
    } else {
      if ( add ) {
        this.groupByColumns.push(field);
      }
    }
  }
  customFilterPredicate(data: any | Group, filter: string): boolean {
    return (data instanceof Group) ? data.visible : this.getDataRowVisible(data);
  }
  getDataRowVisible(data: any): boolean {
    const groupRows = this.dataSource.data.filter(
      row => {
        if (!(row instanceof Group)) {
          return false;
        }
        let match = true;
        this.groupByColumns.forEach(column => {
          if (!row[column] || !data[column] || row[column] !== data[column]) {
            match = false;
          }
        });
        return match;
      }
    );

    if (groupRows.length === 0) {
      return true;
    }
    const parent = groupRows[0] as Group;
    return parent.visible && parent.expanded;
  }
  groupHeaderClick(row) {
    row.expanded = !row.expanded; 
    this.dataSource.filter = performance.now().toString();
  }
  addGroups(data: any[], groupByColumns: string[]): any[] {
    const rootGroup = new Group();
    rootGroup.expanded = true;
    return this.getSublevel(data, 0, groupByColumns, rootGroup);
  }

  getSublevel(data: any[], level: number, groupByColumns: string[], parent: Group): any[] {
    if (level >= groupByColumns.length) {
      return data;
    }
    const groups = this.uniqueBy(
      data.map(
        row => {
          const result = new Group();
          result.expanded = false;
          result.level = level + 1;
          result.parent = parent;
          for (let i = 0; i <= level; i++) {
            result[groupByColumns[i]] = row[groupByColumns[i]];
          }
          return result;
        }
      ),
      JSON.stringify);
    const currentColumn = groupByColumns[level];
    let subGroups = [];
    groups.forEach(group => {
      const rowsInGroup = data.filter(row => group[currentColumn] === row[currentColumn]);
      group.totalCounts = rowsInGroup.length;
      const subGroup = this.getSublevel(rowsInGroup, level + 1, groupByColumns, group);
      subGroup.unshift(group);
      subGroups = subGroups.concat(subGroup);
    });
    return subGroups;
  }
  uniqueBy(a, key) {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  }

  isGroup(index, item): boolean {
    return item.level;
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  public reset() {
    if(this.recordFound == true){
      this.dataSource.data = null;
    }
    this.Search.reset({
      batch_id: '',
      process_id: ''
    });
    this.recordFound = false;
  }
}

