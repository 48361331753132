import { Component, OnInit, ChangeDetectorRef, AfterContentChecked } from "@angular/core";
import { Router } from "@angular/router";
import { Constants } from '../shared/constants.all';
import { DownloadArchiveStructure } from "./DownloadArchiveStructure";
import { DownloadArchiveModel } from "../models/DownloadArchiveModel";
import { Services } from "../shared/services";
import { FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { LoaderService } from "../loader/loader.service";
import { ConfigService } from "../shared/configService";

@Component({
    selector: 'App-DownloadArchive',
    templateUrl: './DownloadArchive.Component.html',
    styleUrls: ['./DownloadArchive.Component.css']
})
export class DownloadArchive implements OnInit {

    form: FormGroup;
    errorMessageActive: boolean = Constants.FALSE;
    successMessageActive: boolean = Constants.FALSE;

    errorMessage?: string = Constants.Undefined;
    successMessage?: string = Constants.Undefined;
    isButtonDisabled?: boolean = Constants.TRUE;

    apiURL: string;

    constructor(private _router: Router, fb: FormBuilder, private _Service: Services, private _loader: LoaderService, private _appConfigService: ConfigService,
        private _changeDetector: ChangeDetectorRef) {

        this.form = fb.group({
            hideRequired: Constants.FALSE,
            floatLabel: Constants.FloatLabel_AUTO
        });

        this.form = new FormGroup(
            {
                apiKey: new FormControl({ value: '', disabled: Constants.FALSE },
                ),
                continue_workflow: new FormControl({ value: Constants.FALSE, disabled: Constants.FALSE }),
                batch_id: new FormControl({ value: '', disabled: Constants.FALSE },
                    [Validators.required]),
                file_name: new FormControl({ value: '', disabled: Constants.FALSE },
                    [Validators.required]),
                url: new FormControl({
                    value: '',
                    disabled: Constants.FALSE
                },
                    [Validators.required,
                    Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'),
                    Validators.maxLength(Constants.OneFifty),
                    Validators.minLength(Constants.Three)]),
                email: new FormControl({ value: '', disabled: Constants.FALSE },
                    [Validators.pattern('^[a-zA-Z0-9._% -]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$')]
                )
            });
    }



    async ngOnInit() {
        this.apiURL = this._appConfigService.getConfig().BaseURL +this._appConfigService.getConfig().APIDownloadArchive;
    }


    ngAfterContentChecked(): void {
        this._changeDetector.detectChanges();
    }

    cancel() {
        this.isButtonDisabled = Constants.FALSE;
        this.form.reset({
            apiKey: '',
            batch_id: '',
            file_name: '',
            url: '',
            email: '',
            continue_workflow: false
        });
        this.errorMessageActive = false;
        this.successMessageActive = false;
        this.errorMessage = "";
        this.successMessage = "";
    }

    isDisabled() {
        if (this.form.touched)
            this.isButtonDisabled = Constants.FALSE;
        return this.isButtonDisabled;
    }

    public run = (_passedDownloadDetail: DownloadArchiveStructure) => {
        if (this.form.valid && this.form.touched) {
            this.runViaApi(_passedDownloadDetail);
        }
    }

    private runViaApi(_details: DownloadArchiveStructure) {
        this._loader.show();
        var requestItem = new DownloadArchiveModel();
        requestItem.batchId = _details.batch_id.toString();
        requestItem.apiKey = _details.apiKey;
        requestItem.fileName = _details.file_name;
        requestItem.url = _details.url.replace('https://','');
        requestItem.email = _details.email;
        requestItem.continueWorkflow = _details.continue_workflow;

        this._Service.callAPI(requestItem, this.apiURL)
            .subscribe(
                {
                    next: response => {
                        this.isButtonDisabled = Constants.TRUE;
                        if (response !== undefined) {
                            this.successMessage = "Success - " + response;
                            this.successMessageActive = Constants.TRUE;
                            this.errorMessageActive = Constants.FALSE;
                            this._loader.hide();
                        }
                        else {
                            this.errorMessage = "Error in caling API";
                            this.errorMessageActive = Constants.TRUE;
                            this.successMessageActive = Constants.FALSE;
                            this._loader.hide();
                        }

                    },
                    error: err => {
                        this.errorMessage = err();
                        this.errorMessageActive = Constants.TRUE;
                        this.successMessageActive = Constants.FALSE;
                        this._loader.hide();
                    }
                }
            )

    }

    public hasError = (controlName: string, errorName: string) => {
        return this.form.touched && this.form.controls[controlName]?.hasError(errorName);
    }

}
