<mat-card>
    <div *ngIf="errorMessageActive" class="alert alert-danger">
        {{ errorMessage }}
    </div>
    <div *ngIf="successMessageActive" class="alert alert-success">
        {{ successMessage }}
    </div>
    <form novalidate [formGroup]="form" autocomplete="off" (ngSubmit)="run(form.value)" fxLayout="column wrap"
        fxLayoutAlign="center center" fxLayoutGap="10px">
        <mat-card-header>Download Archive</mat-card-header>
        <mat-card-content>
            <mat-form-field>
                <mat-label>Batch Id</mat-label>
                <input matInput #input_batch_id placeholder="Batch Id" id="batch_id" formControlName="batch_id" />
                <mat-error *ngIf="hasError('batch_id', 'required')">Batch Id is required</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>File Name</mat-label>
                <input matInput #input_file_name placeholder="File Name" id="file_name" formControlName="file_name" />
                <mat-error *ngIf="hasError('file_name', 'required')">File Name is required</mat-error>
            </mat-form-field>
            <mat-form-field>               
                <mat-label> Url</mat-label>
                <input matInput #inputurl placeholder="Url" minlength="3" maxlength="250" id="url"
                    formControlName="url" />
                <mat-error *ngIf="hasError('url', 'maxlength')">Only 150 characters are allowed</mat-error>
                <mat-error *ngIf="hasError('url', 'minlength')">At least 3 characters are required</mat-error>
                <mat-error *ngIf="hasError('url', 'pattern')">Invalid Url</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>eMail</mat-label>
                <input matInput #input_file_name placeholder="eMail" id="email" formControlName="email" />
                <mat-error *ngIf="hasError('email', 'pattern')">Invalid Email</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Galaxy Australia API Key</mat-label>
                <input matInput #input_user placeholder="API Key" id="apiKey" formControlName="apiKey" />
            </mat-form-field>
            <br>
            <div>
                <span> <mat-checkbox formControlName="continue_workflow" labelPosition="before">Continue
                        Workflow</mat-checkbox>
                </span>
            </div>
        </mat-card-content>



        <mat-card-actions>
            <button mat-raised-button [style.background]="'#748b31'"
                matTooltip="Click here to download details for batch id {{input_batch_id.value}}" type="submit"
                [disabled]="!form.valid || isDisabled() ">
                Run
            </button>
            <span></span>
            <button type="button" mat-raised-button color="warn" (click)="cancel()" matTooltip="Click here to Cancel"
                [disabled]="isDisabled() ">
                Cancel
            </button>
        </mat-card-actions>
    </form>
</mat-card>